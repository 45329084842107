import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getStoreThemeUrl, getLanguageThemeUrl, getBrandThemeUrl, appBrandCustomization
} from '@reducers/theme';
import { updateHeaderData, closeNavBar } from '@reducers/header';
import getMetaData from '@constants/meta';
import { userInfo } from '@utility';
import Loader from '@components/common/Loader';
import { APP_CONFIG } from '@constants';
import { getObjectValue } from '@utility/utills';
import cn from 'classnames';
import styles from './onRouteChange.module.css';
import {
  changeMenu,
  getMenuBasedOnLanguage,
  replaceCart,
  clearMenu
} from '@reducers/orders';

const OnRouteChange = ({ restaurantInfo, loading, ...props }) => {
  // let currentPathname = null;
  // let currentSearch = null;
  const onRouteChangeEvent = (location) => {
    window.sessionStorage.setItem('currentPath', window.location.pathname);
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
    };
    // const { history } = props;
    // if (action === 'PUSH') {
    //   if (location.pathname !== currentPathname || location.search !== currentSearch) {
    //     currentPathname = location.pathname;
    //     currentSearch = location.search;
    //     history.push({
    //       pathname: location.pathname,
    //       search: location.search
    //     });
    //   }
    // } else {
    //   window.onpopstate = () => {
    //     history.go(1);
    //   };
    // }
    props.updateHeaderData(getMetaData(location));
  };

  const isCheckInPath = () => {
    let isCheckInUrl = false;
    const checkInUrls = ['/checkIn/', '/kioskOrder/', '/s/', '/code?'];
    checkInUrls.forEach((cUrl) => {
      if (props.history.location.pathname.includes(cUrl)) {
        isCheckInUrl = true;
      }
    });
    return isCheckInUrl;
  };

  const favouriteStyle = props.themeProperties && props.themeProperties.style && JSON.parse(props.themeProperties.style);
  const odFullWidthContainers = favouriteStyle && favouriteStyle.odFullWidthContainers;
  useEffect(() => {
    if (!userInfo.isSessionHasToken() && !isCheckInPath()) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      props.history.replace(`${APP_CONFIG.BASE_URL}/dashboard`);
    }
    if (getObjectValue(() => props.popInData.store.brandId, null)) {
      props.getStoreThemeUrl(props.popInData.store.brandId, props.popInData.store.id);
    }
    onRouteChangeEvent(props.location);
    props.history.listen(onRouteChangeEvent);
  }, []);

  // document.title = props.popInData && props.popInData.store && props.popInData.store.name ? props.popInData.store.name : 'OneDine';
  document.title = props.themeProperties && props.themeProperties.brandName ? props.themeProperties.brandName : 'OneDine';
  const fullWidth = props.location.pathname === '/orders' || props.location.pathname.includes('/order-item') || props.location.pathname === '/upsell' || props.location.pathname.includes('/selected-cart') || props.location.pathname.includes('/tip');
  // const isFromReceiptPage = getObjectValue(() => props.history.location.state.isFromReceiptPage, null);

  // const getSearchParameters = (url) => {
  //   let searchParam = null;
  //   const tempURL = url && url.split('?');
  //   if (isFromReceiptPage) {
  //     searchParam = `flowType=${props.popInData.flowType}&orderPaid=true&splitType=null`;
  //   }
  //   if (tempURL && tempURL.length > 0 && tempURL[0] === '/loyalty-offers') {
  //     searchParam = tempURL[1];
  //   }
  //   if (tempURL && tempURL.length > 0 && tempURL[0] === '/waitlist-confirmation') {
  //     searchParam = tempURL[1];
  //   }
  //   return searchParam;
  // };

  // const getPathName = (url) => {
  //   let result = url;
  //   const tempURL = url && url.split('?');
  //   if (tempURL && tempURL.length > 0 && tempURL[0] === '/loyalty-offers') {
  //     result = tempURL[0];
  //   }
  //   if (tempURL && tempURL.length > 0 && tempURL[0] === '/waitlist-confirmation') {
  //     result = tempURL[0];
  //   }
  //   return result;
  // };

  // const onNavigate = (url, isClose) => {
  //   if (props.menu.type === 1 && !isClose) {
  //     props.closeNavBar(false);
  //   }
  //   const selectedTip = JSON.parse(window.sessionStorage.getItem('tip'));
  //   if (url !== '/tip' && selectedTip !== null) {
  //     window.sessionStorage.removeItem('tip');
  //     window.sessionStorage.removeItem('tipRoundUpEnable');
  //   }
  //   switch (url) {
  //     case '/logout':
  //       props.logout();
  //       window.localStorage.clear();
  //       window.sessionStorage.clear();
  //       window.location.reload();
  //       window.location.href = '/dashboard';
  //       // props.history.replace(`${APP_CONFIG.BASE_URL}/dashboard`);
  //       break;
  //     case '/tip':
  //       if (window.location.pathname === '/gift-card') {
  //         props.history.replace({ pathname: `${APP_CONFIG.BASE_URL}/tip`, search: '?backTo=tip' });
  //       } else {
  //         props.history.replace(`${APP_CONFIG.BASE_URL}/tip`);
  //       }
  //       break;
  //     default:
  //       // window.localStorage.setItem('checkInRequired', false);
  //       props.history.replace({
  //         pathname: `${APP_CONFIG.BASE_URL}${getPathName(url)}`,
  //         state: props.history.location.state,
  //         search: getSearchParameters(url)
  //       });
  //       break;
  //   }
  // };

  window.redirectFromMobileAppTo = (type) => {
    if (type === '1') {
      props.history.replace(`${APP_CONFIG.BASE_URL}/orders`);
      // onNavigate(props.closeButton.controlUrl, true);
    } else if (type === '2') {
      if (window.location.pathname.includes('/selected-cart')) {
        props.history.replace(`${APP_CONFIG.BASE_URL}/${window.location.pathname}`);
      } else {
        props.history.replace(`${APP_CONFIG.BASE_URL}/upsell`);
      }
    }
  };

  const [launcher, setLauncher] = useState(null);
  const [loader, setLoader] = useState(false);
  const isIos = JSON.parse(window.localStorage.getItem('ios'));
  const userDetails = userInfo.getUserBasicDetails() || {};

  const getHostName = () => {
    let result = 'developement';
    if (window.location.href.indexOf('app.onedine.com') !== -1) {
      result = 'production';
    }
    if (window.location.href.indexOf('app.dev.onedine.com') !== -1) {
      result = 'developement';
    }
    if (window.location.href.indexOf('app.uat.onedine.com') !== -1) {
      result = 'developement';
    }
    return result;
  };

  const getPageName = () => {
    let result = '';
    if (window.location.pathname.includes('/payment-success')) {
      result = `${getHostName()}-receipt-page`;
    }
    if (window.location.pathname.includes('/receipt')) {
      result = `${getHostName()}-receipt-page`;
    }
    return result;
  };

  const getCurrency = (data) => {
    return getObjectValue(() => data.store.country, false) && (data.store.country === '+1' || data.store.country === 1) ? 'USD' : 'EURO';
  };

  const getCountry = (data) => {
    return getObjectValue(() => data.store.country, false) && (data.store.country === '+1' || data.store.country === 1) ? 'US' : 'UK';
  };

  const getRoktAttributes = (userData, popInData) => {
    return {
      email: getObjectValue(() => userData.emailAddress, null) || '',
      firstname: getObjectValue(() => userData.firstName, null) || '',
      confirmationref: getObjectValue(() => popInData.checkHeader.referenceId, null) || '',
      amount: getObjectValue(() => popInData.totalTicketAmount, null) || null,
      paymenttype: getObjectValue(() => popInData.ticketPaymentTypeName, null) || '',
      ccbin: getObjectValue(() => popInData.cardLast4, null) || null,
      zipcode: '',
      country: getCountry(popInData),
      language: getObjectValue(() => popInData.store.country, null) || '',
      cartItems: JSON.stringify(getObjectValue(() => popInData.items, null) || []) || [],
      lastname: getObjectValue(() => userData.lastName, null) || '',
      mobile: getObjectValue(() => userData.phoneNumber, null) || '',
      currency: getCurrency(popInData),
      majorCat: '',
      majorCatID: '',
      sku: '',
      productName: '',
      quantity: '',
      price: '',
      numberOfProducts: '',
    };
  };

  useEffect(() => {
    const isDeliveryOrder = JSON.parse(window.sessionStorage.getItem('isDeliveryOrder'));
    const deliveryData = window.sessionStorage.getItem('deliveryData') || null;
    if (!launcher) {
      setLoader(false);
      return;
    }
    let selectionPromise = null;
    if (!isIos && (window.location.pathname.includes('/payment-success') || window.location.pathname.includes('/receipt'))) {
      selectionPromise = launcher.selectPlacements({
        attributes: {
          ...getRoktAttributes(userDetails, props.popInData || null),
          ...(isDeliveryOrder && {
            address1: deliveryData.address1,
            address2: deliveryData.address1,
            delivery: getObjectValue(() => props.popInData.checkHeader.orders[0].delivery, false)
          })
        },
        identifier: getPageName(),
      });
      setLoader(false);
    }
    // eslint-disable-next-line consistent-return
    return () => {
      if (selectionPromise) {
        setLoader(false);
        // When the page closes, remove all the Rokt placements
        selectionPromise.then(selection => selection.close());
      }
    };
  }, [launcher]);

  const loadRoktLauncher = (data) => {
    setLoader(true);
    (async () => {
      // Guards against Rokt script being still loaded into the application when the context is created
      // eslint-disable-next-line no-return-assign
      await new Promise(resolve => (window.Rokt
        ? resolve()
        : (document.getElementById('rokt-launcher').onload = resolve)));
      const launcherInstance = await window.Rokt.createLauncher({
        accountId: data,
        sandbox: true,
      });
      setLauncher(launcherInstance);
    })();
  };

  /** Rokt Context */
  useEffect(() => {
    const accountId = getObjectValue(() => props.popInData.store.advertisingInfo.accountId, false);
    let roktScript;
    if (!isIos && accountId && (window.location.pathname.includes('/payment-success') || window.location.pathname.includes('/receipt'))) {
      roktScript = document.createElement('script');
      roktScript.setAttribute('src', 'https://apps.rokt.com/wsdk/integrations/launcher.js');
      roktScript.setAttribute('type', 'text/javascript');
      roktScript.setAttribute('id', 'rokt-launcher');
      roktScript.setAttribute('crossorigin', 'anonymous');
      roktScript.setAttribute('fetchpriority', 'high');
      roktScript.setAttribute('async', true);
      roktScript.onload = () => loadRoktLauncher(accountId);
      if (!document.getElementById('roktScript')) {
        document.getElementsByTagName('head')[0].appendChild(roktScript);
      }
    }
    return () => {
      setLoader(false);
      if (roktScript && roktScript.parentNode) {
        roktScript.parentNode.removeChild(roktScript);
      }
      if (launcher) {
        launcher.terminate();
      }
    };
  }, [window.location.pathname]);

  window.getLoginDetailsFromApp = (data) => {
    const userData = JSON.parse(data);
    window.localStorage.setItem('userData', JSON.stringify(userData));
    window.location.reload();
    window.scrollTo(0, 0);
  };

  window.getLanguageIdFromMobileApp = (data) => {
    const appData = JSON.parse(data);
    const languageData = getObjectValue(() => appData, null);
    if (languageData && languageData.languageId) {
      window.sessionStorage.setItem('languageId', languageData.languageId);
      window.location.reload();
      window.scrollTo(0, 0);
    }
  };

  const bgImg = (themePropertiesUrlList) => {
    let url = '';
    const urlPath = window.location.pathname;
    if (getObjectValue(() => themePropertiesUrlList.homePageBackgroundImageUrl, '') && urlPath === '/home') {
      url = getObjectValue(() => themePropertiesUrlList.homePageBackgroundImageUrl, '');
    } else if (getObjectValue(() => themePropertiesUrlList.receiptPageBackgroundImageUrl, '') && urlPath === '/receipt') {
      url = getObjectValue(() => themePropertiesUrlList.receiptPageBackgroundImageUrl, '');
    } else {
      url = getObjectValue(() => themePropertiesUrlList.backgroundImageUrl, '');
    }
    return url;
  };

  const returnPadding = (condition, value1, value2) => {
    if (condition) return value1;
    return value2;
  };

  const onFailureThemeApi = (err) => {
    props.setNotification({
      type: 'danger',
      message: err.message
    });
  };

  const onSuccessBrandCustomization = (res) => {
    window.sessionStorage.setItem('themeProperties', JSON.stringify(res));
  };

  const brandInfoId = JSON.parse(window.sessionStorage.getItem('brandId')) || null;
  useEffect(() => {
    if (brandInfoId) {
      props.getBrandThemeUrl(brandInfoId).then(() => {
        props.appBrandCustomization(brandInfoId).then(onSuccessBrandCustomization).catch(onFailureThemeApi);
      });
    }
  }, []);

  const receiptLinkType = window.location.pathname.includes('/receipt/display') || window.location.pathname.includes('/receipt/giftCard');
  return (
    <div style={{ background: getObjectValue(() => props.themeProperties, '') && `url(${bgImg(props.themeProperties)})  center center no-repeat fixed`, overflow: 'hidden' }} className={`dashboard-page-bg od-font od-app-background-color ${(props.location.pathname.includes('/tip') && window.innerWidth < 375) ? 'pb-130' : ''} ${props.headerBar.show ? returnPadding(odFullWidthContainers && props.location.pathname === '/selected-cart/0' && window.innerWidth < 1024, 'pt-40', window.innerWidth > 1023 ? cn(window.location.pathname.includes('/order-item') ? styles.paddingTop62px : 'pt-80') : 'pt-60') : ''} ${(odFullWidthContainers && props.location.pathname === '/selected-cart/0') && 'pt-40'} ${(odFullWidthContainers && props.location.pathname === '/home') && 'pt-0'}  ${(odFullWidthContainers && props.location.pathname === '/receipt') ? (returnPadding(window.innerWidth > 1023, 'pt-20', 'pt-0')) : ''}  ${props.footer && props.footer.show ? 'pb-60' : ''} ${(window.innerWidth > 1023 && fullWidth) ? 'pb-0' : ''} ${window.innerWidth > 1023 && !receiptLinkType ? 'container' : ''} ${getObjectValue(() => props.popInData.store.guestAppFeatures.supportsDisplayCartAsButton, true) && (!props.location.pathname.includes('/locator') && !props.location.pathname.includes('/listing') && !props.location.pathname.includes('/KDS')) ? 'pb-130' : ''}`}>
      {/* <div id="pageContent" className={`${fullWidth ? '' : 'col-lg-4 offset-lg-4 col-md-6 offset-md-3'}`}> */}
      <div className={cn(window.innerWidth < 1023 ? 'p-0' : `${fullWidth ? '' : `${window.innerWidth > 1023 ? (!receiptLinkType && 'container-fluid') : 'container'}`}`)}>
        <div id="pageContent">
          {(loading || loader) && <Loader />}
          {props.children}
        </div>
      </div>
    </div>
  );
};
const mapSateToProps = ({
  oauth,
  theme,
  header,
  controller
}) => ({
  loading: header.loading || theme.loading,
  themeProperties: theme.themeProperties,
  isUserLogin: oauth.isUserLogin || window.localStorage.getItem('isUserLogin'),
  isRestaurantBarOpen: header.isRestaurantBarOpen,
  isNavOpen: header.isNavOpen,
  popInData: controller.popInData,
  headerBar: header.headerBar,
  closeButton: header.closeButton,
  menu: header.menu,
  restaurantInfo: header.restaurantInfo,
  footer: header.footer
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getStoreThemeUrl,
  updateHeaderData,
  changeMenu,
  getMenuBasedOnLanguage,
  replaceCart,
  clearMenu,
  getLanguageThemeUrl,
  closeNavBar,
  getBrandThemeUrl,
  appBrandCustomization
}, dispatch);
export default withRouter(connect(mapSateToProps, mapDispatchToProps)(OnRouteChange));
